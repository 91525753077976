@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@layer base {
  html,
  body {
    font-family: Montserrat, system-ui, sans-serif;
    overflow: overlay;
  }
}

.wordSpace {
  word-break: break-word;
  letter-spacing: 4px;
  line-height: 14px;
  font-size: 24px;
  -webkit-text-security: disc;
}

/** Custom toast style*/
.Toastify__toast {
  margin-bottom: 16px !important;
}
.Toastify__toast-container {
  padding: 0 !important;
  width: auto !important;
}
.Toastify__toast--error {
  width: 453px !important;
  background: #cf1e24 !important;
}
.Toastify__toast--success {
  width: 453px !important;
  background: #3b994a !important;
}
.Toastify__toast--warning {
  width: 453px !important;
  background: #fff278 !important;
}
.Toastify__toast--info {
  display: flex !important;
  align-items: center !important;
  width: 302px !important;
  background: #09050f !important;
}
.Toastify__close-button {
  margin: 5px 8px !important;
}
.Toastify__toast-body {
  padding-right: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
/* Edge */
input::-ms-reveal {
  display: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.ReactModal__Overlay--after-open {
  background-color: rgba(9, 5, 15, 0.25) !important;
  z-index: 99;
  backdrop-filter: blur(3px);
}

.ReactModal__Content--after-open {
  overflow: initial !important;
}

#main {
  padding: 16px;
  border-radius: 8px;
  background-color: #09050f;
  font-weight: 500;
}

.react-date-picker__wrapper {
  align-items: center;
  padding-left: 13px;
  padding-right: 16px;
  border-radius: 12px;
  font-weight: 700;
  border: 2px solid #dededf !important;
}

.react-date-picker__button {
  padding: 0 !important;
}

.react-date-picker__calendar {
  top: 60px !important;
  left: 0 !important;
  font-weight: 700;
  font-size: 20px;
  font-family: Montserrat, system-ui, sans-serif;
  width: 488px !important;
  z-index: 50 !important;
}

.react-calendar__navigation__label {
  font-family: Montserrat, system-ui, sans-serif;
  color: #312e36;
  border-radius: 8px;
}

.react-date-picker__calendar--open {
  border-radius: 20px !important;
  height: fit-content !important;
  padding: 10px;
  background-color: #fdfdfd;
  box-shadow: 1px 8px 11px 2px rgba(128, 128, 128, 0.47);
  -webkit-box-shadow: 1px 8px 11px 2px rgba(128, 128, 128, 0.47);
  -moz-box-shadow: 1px 8px 11px 2px rgba(128, 128, 128, 0.47);
}

.react-calendar {
  width: 488px;
  padding: 18px;
  border: none !important;
}

.react-calendar__tile {
  margin: 10px 0 !important;
  height: 40px;
  font-family: Montserrat, system-ui, sans-serif;
  color: #312e36;
}

.react-calendar__tile--now {
  background-color: #dededf !important;
  height: 40px;
  border-radius: 8px;
  text-decoration: dotted !important;
}

.react-calendar__tile:hover {
  background-color: #ebddff !important;
  border-radius: 8px;
}

.react-calendar__tile--active {
  background: #6d4caf !important;
  border-radius: 8px;
  color: #fdfdfd;
}

.react-calendar__month-view__weekdays__weekday {
  margin: 10px 0;
  font-weight: 500;
  font-family: Montserrat, system-ui, sans-serif;
  text-transform: none;
  font-size: 16px;
}

.react-calendar__month-view__days__day {
  font-family: Montserrat, system-ui, sans-serif;
}

.react-calendar__month-view__days__day--weekend {
  color: #312e36 !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}

abbr {
  text-decoration: none;
}

.react-calendar__month-view {
  width: 424px;
}

.react-calendar__navigation {
  width: 424px;
  padding-bottom: 10px;
}

.react-calendar__viewContainer {
  border-top: 1px solid #dededf;
  padding-top: 10px;
  width: 400px;
  margin-left: 16px;
}

.react-calendar__month-view {
  width: 100%;
}

.react-calendar__month-view__weekdays {
  width: 424px;
  margin-left: -12px;
  font-weight: 500;
}

.react-calendar__month-view__days {
  width: 424px;
  margin-left: -11px;
}

.react-calendar__tile--hasActive {
  height: 66px;
}

.react-calendar__year-view__months__month {
  height: 66px;
  padding-top: 25px !important;
}

.react-calendar__decade-view__years__year {
  height: 66px;
  padding-top: 25px !important;
}

.react-calendar__century-view__decades__decade {
  height: 66px;
  padding-top: 25px !important;
}

.react-calendar__navigation__arrow {
  border-radius: 8px;
}

.react-calendar__navigation__arrow:hover {
  background-color: #ebddff !important;
  border-radius: 8px;
}

.react-calendar__navigation__label:hover {
  background-color: #ebddff !important;
  border-radius: 8px;
}

/*
 *  Custom - scrollbar
 */

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #cfc9c9;
}

.cus-scrollbar::-webkit-scrollbar-track,
.small-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
.cus-scrollbar::-webkit-scrollbar,
.small-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}
.cus-scrollbar::-webkit-scrollbar-thumb,
.small-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #dededf;
}
.small-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.tabscroll::-webkit-scrollbar {
  display: none;
}
